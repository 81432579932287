import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  InputBase,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import conf from "../../config/conf_sql.json";
import { Link } from "react-router-dom";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function NoticeList({
  crew_id,
  club_id,
  activity_id,
  crew_nick,
  api,
  order,
  text,
  admin_level,
  club_nick,
  limit,
  needSearch,
}) {
  const [noticeList, setNoticeList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const sortOrder = order === "asc" ? 1 : -1;
  const noticeRefs = useRef({});

  useEffect(() => {
    ApiHeader.get(api)
      .then((response) => {
        setNoticeList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "NoticeList.js, callApi");
      });

    // 세션 스토리지에서 페이지 번호를 가져와서 설정합니다.
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(Number(savedPage));
      sessionStorage.removeItem("currentPage");
    }

    // 저장된 게시물 ID로 스크롤하기
    const savedNoticeId = sessionStorage.getItem("selectedNoticeId");
    if (savedNoticeId && noticeRefs.current[savedNoticeId]) {
      noticeRefs.current[savedNoticeId].scrollIntoView({ behavior: "smooth" });
      sessionStorage.removeItem("selectedNoticeId"); // 스크롤 후 ID 제거
    }
  }, [api]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleLinkClick = (id) => {
    console.log("NoticeList.js, handleLinkClick, id: ", id);
    if (id === 0) {
      sessionStorage.setItem("currentPage", 1);
    } else {
      sessionStorage.setItem("currentPage", currentPage); // 클릭한 페이지 번호 저장
      sessionStorage.setItem("selectedNoticeId", id); // 클릭한 게시물 ID 저장
    }
  };

  let filterSortData = noticeList
    .filter(
      (row) =>
        row.notice_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.notice_category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.nick.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => (a.sort > b.sort ? sortOrder : -sortOrder));

  if (limit) {
    filterSortData = filterSortData.slice(0, limit);
  }

  const itemsPerPage = 10;
  const paginatedData = filterSortData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filterSortData.length / itemsPerPage);
  const pageNeighbours = 1;
  const totalNumbers = pageNeighbours * 2 + 1;

  let startPage = Math.max(2, currentPage - pageNeighbours);
  let endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

  if (endPage - startPage + 1 < totalNumbers) {
    if (currentPage < totalPages / 2) {
      endPage = Math.min(
        totalPages - 1,
        endPage + totalNumbers - (endPage - startPage + 1)
      );
    } else {
      startPage = Math.max(
        2,
        startPage - totalNumbers + (endPage - startPage + 1)
      );
    }
  }

  const pageNumbers = [];
  if (filterSortData.length > itemsPerPage) {
    pageNumbers.push(1);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push(totalPages);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    sessionStorage.setItem("currentPage", newPage); // 페이지 번호를 세션 스토리지에 저장합니다.
  };

  const tableData = paginatedData.map((row) => {
    return (
      <TableRow
        key={row.id}
        ref={(el) => (noticeRefs.current[row.id] = el)} // 각 게시물의 ref 설정
      >
        <TableCell key={`${row.id}-category`} sx={{ fontSize: 12, width: 38 }}>
          <Link
            to={`/${club_nick}/notices/bulletin?category=${row.notice_category}`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
            onClick={() => handleLinkClick(0)} // 클릭 시 페이지 번호 및 ID 저장
          >
            {row.notice_category}
          </Link>
        </TableCell>
        <TableCell key={`${row.id}-notice`} style={{ whiteSpace: "pre-wrap" }}>
          <Link
            to={`/${club_nick}/notice/${hashids.encode(row.id)}`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
            onClick={() => handleLinkClick(row.id)} // 클릭 시 페이지 번호 및 ID 저장
          >
            {row.notice_title}
          </Link>
        </TableCell>
        <TableCell key={`${row.id}-date`} sx={{ width: 88 }}>
          <span className="small-text">
            {moment(row.created_time)
              .subtract(conf.time_offset, "hours")
              .format("M/D(ddd) H:mm")}
            <br />
            {row.nick}
          </span>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Toolbar>
        <Typography variant="h7">{text}</Typography>
        {needSearch && (
          <>
            <Typography
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            />
            <SearchIcon />
            <InputBase
              type="text"
              className="search"
              onChange={handleChange}
              placeholder="Search..."
            />
          </>
        )}
      </Toolbar>
      <Table>
        <TableBody>{tableData}</TableBody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {pageNumbers.map((number, index) => (
          <Button
            key={index}
            onClick={() => number !== "..." && handlePageChange(number)}
            disabled={number === "..." || number === currentPage}
          >
            {number}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default NoticeList;
