import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import conf from "../../config/conf_sql.json";
import useCrewData from "../Common/CrewData";
import ApiHeader from "../Common/ApiHeader";
import SortIcon from "@mui/icons-material/Sort";
import "./animation.css"; // CSS 파일 import
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function CrewList(props) {
  const {
    api,
    lst_col,
    activity_id,
    activity_time,
    activity_subgroup,
    activity_owner,
    text,
    club_nick,
    activityChanged,
    correctPassword,
  } = props;
  const order = props.order == "asc" ? 1 : -1;
  const [crewList, setCrewList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const subgroups = activity_subgroup ? activity_subgroup.split("\n") : [];
  const [levels, setLevels] = useState({});
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  useEffect(() => {
    ApiHeader.get(api)
      .then((response) => {
        setCrewList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "CrewList.js, useEffect, ApiHeader.get");
      });
  }, [activityChanged]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`)
      .then((response) => {
        setLevels(response.data.levels && JSON.parse(response.data.levels));
      })
      .catch((e) => {
        ErrorHandler(e, "CrewList.js, useEffect, club_nick, ApiHeader.");
      });
  }, [club_nick]);

  const handleAttendee = async (id, event, newAlignment) => {
    const newState =
      newAlignment === "attend" ? 2 : newAlignment === "noshow" ? 0 : 1;

    // 상태를 로컬에서 먼저 업데이트하여 UI가 즉시 반응하도록 함
    setCrewList((prevList) =>
      prevList.map((row) =>
        row.crew_id === id ? { ...row, state: newState } : row
      )
    );

    // Update the database with the new state
    try {
      await ApiHeader.put(
        `/api/v1/attendees/${hashids.encode(id)}/${activity_id}`,
        {
          state: newState,
        }
      );
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleAttendee, attendees put");
    }
  };

  const isSubgroupChangeTime = (activityTime) => {
    const now = moment();
    const end = moment(activityTime).subtract(conf.time_offset).add(1, "hours");
    return now.isBefore(end);
  };

  const isOrganizerCheckinTime = (activityTime) => {
    const now = moment();
    const start = moment(activityTime)
      .subtract(conf.time_offset)
      .subtract(1, "hours");
    const end = moment(activityTime).subtract(conf.time_offset).add(6, "hours");
    return now.isBetween(start, end);
  };

  const isMemberCheckInTime = (activityTime) => {
    const now = moment();
    const start = moment(activityTime)
      .subtract(conf.time_offset)
      .subtract(30, "minutes");
    const end = moment(activityTime)
      .subtract(conf.time_offset)
      .add(60, "minutes");
    return now.isBetween(start, end);
  };

  // 정렬 기능 추가
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleSort = (key) => {
    const json_key = {
      이름: "nick",
      생일: "birthday",
      가입일: "created_time",
      소그룹: "subgroup",
      크루등급: "admin_level",
    };

    const isAsc =
      sortConfig.key === json_key[key] && sortConfig.direction === "ascending";
    setSortConfig({
      key: json_key[key],
      direction: isAsc ? "descending" : "ascending",
    });
  };

  const sortedData = React.useMemo(() => {
    // crewList에 crewId가 포함되어 있으면 최상단으로 이동
    const crewIdIndex = crewList.findIndex(
      (row) => row.crew_id === hashids.decode(crewId)[0]
    );
    if (crewIdIndex > 0) {
      const crewIdRow = crewList.splice(crewIdIndex, 1);
      crewList.unshift(crewIdRow[0]);
    }

    if (!sortConfig.key) return crewList; // tableData는 정렬할 데이터 배열입니다.
    return [...crewList].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [crewList, sortConfig]);

  const filterSortData = sortedData.filter(
    (row) =>
      (row.nick ? row.nick.toLowerCase().includes(searchTerm) : false) ||
      (levels[row.admin_level]
        ? levels[row.admin_level].toLowerCase().includes(searchTerm)
        : false)
  );

  const tableData = filterSortData.map((row) => {
    return (
      <TableRow key={row.id}>
        <TableCell>
          {row.admin_level === 9 ? "🥇" : row.admin_level >= 7 ? "🥈" : ""}
          <Link
            to={`/${club_nick}/crew/${hashids.encode(row.crew_id)}`}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            {row.nick}
          </Link>
        </TableCell>
        <TableCell>{levels[row.admin_level]}</TableCell>
        <TableCell>
          {moment(row.created_time)
            .subtract(conf.time_offset)
            .format("YYYY.MM.DD")}
        </TableCell>
      </TableRow>
    );
  });

  const filterAttendeeData = sortedData.filter(
    (row) =>
      (row.nick ? row.nick.toLowerCase().includes(searchTerm) : false) ||
      (row.subgroup ? row.subgroup.toLowerCase().includes(searchTerm) : false)
  );

  // 서브그룹 변경 다이얼로그
  const [open, setOpen] = useState(false);
  const [selectedCrewId, setSelectedCrewId] = useState(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);

  const handleOpenDialog = (event, crewId) => {
    setSelectedCrewId(crewId);
    setSelectedSubgroup(event.target.value);
    setOpen(true);
  };

  const handleConfirmChange = () => {
    handleSubgroupChange(selectedSubgroup, selectedCrewId);
    setOpen(false);
  };

  const handleSubgroupChange = async (newSubgroup, id) => {
    setCrewList((prevList) =>
      prevList.map((row) =>
        row.crew_id === id ? { ...row, subgroup: newSubgroup } : row
      )
    );

    try {
      await ApiHeader.put(
        `/api/v1/attendees/${hashids.encode(id)}/${activity_id}/subgroup`,
        {
          subgroup: newSubgroup,
        }
      );
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleSubgroupChange, attendees put");
    }
  };

  const handleChange = (e) => {
    try {
      setSearchTerm(e.target.value.toLowerCase());
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleChange");
    }
  };

  // 출석 애니메이션 표현
  const [animate, setAnimate] = useState(false);
  const [pwopen, setpwOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [pendingAttendance, setPendingAttendance] = useState(null);
  const [pwError, setPwError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const totalAttempts = 5;

  const handlepwClickOpen = (crew_id, event, state, newAlignment) => {
    setPendingAttendance({ crew_id, event, state, newAlignment });
    state === 1 && setpwOpen(true);
    state === 2 && animationHeart();
  };

  const handlepwClose = () => {
    setpwOpen(false);
    setPassword("");
    setPendingAttendance(null);
    setPwError(""); // 에러 메시지 초기화
  };

  const handlepwConfirm = () => {
    if (password === correctPassword.toString()) {
      const { crew_id, event, state, newAlignment } = pendingAttendance;
      animationHeart();
      state === 1 && handleAttendee(crew_id, event, newAlignment);
      handlepwClose();
    } else {
      setPwError("코드가 틀렸습니다. 다시 시도하세요.");
      setAttempts((prev) => prev + 1);

      if (attempts + 1 >= totalAttempts) {
        const { crew_id, event } = pendingAttendance;
        handleAttendee(crew_id, event, "noshow");
        handlepwClose();
      }
    }
  };

  const animationHeart = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1000);
  };

  const handlepwChange = (event) => {
    setPassword(event.target.value);
    setPwError("");
  };

  const tableAttendeeData = filterAttendeeData.map((row) => {
    return (
      <TableRow key={row.id}>
        <TableCell key={row.id} sx={{ minWidth: 48 }}>
          {row.admin_level === 9
            ? "🥇"
            : row.admin_level >= 7
            ? "🥈"
            : row.admin_level >= 5
            ? "*"
            : row.admin_level === 1
            ? "¹"
            : ""}
          {adminLevel >= 3 || row.crew_id === hashids.decode(crewId)[0] ? (
            <Link
              to={`/${club_nick}/crew/${hashids.encode(row.crew_id)}`}
              style={{ color: "#3f50b5", textDecoration: "inherit" }}
            >
              {row.nick}
            </Link>
          ) : (
            row.nick
          )}
        </TableCell>
        <TableCell key={row.subgroup} size="small">
          {activity_subgroup &&
          activity_subgroup.split("\n").length > 1 &&
          isSubgroupChangeTime(activity_time) &&
          (adminLevel >= 8 ||
            activity_owner === hashids.decode(crewId)[0] ||
            row.crew_id === hashids.decode(crewId)[0]) ? (
            <Select
              value={row.subgroup}
              onChange={(event) => handleOpenDialog(event, row.crew_id)}
              size="small"
            >
              {[...new Set([row.subgroup, ...subgroups])].map(
                (group, index) => (
                  <MenuItem key={index} value={group}>
                    {group}
                  </MenuItem>
                )
              )}
            </Select>
          ) : (
            row.subgroup
          )}
        </TableCell>
        <TableCell
          key={"ToggleButtonGroup"}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {(adminLevel >= 7 || activity_owner === hashids.decode(crewId)[0]) &&
          isOrganizerCheckinTime(activity_time) ? (
            <ToggleButtonGroup
              color="primary"
              value={
                row.state === 2 ? "attend" : row.state === 0 ? "noshow" : ""
              }
              exclusive
              onChange={(event, newAlignment) =>
                handleAttendee(row.crew_id, event, newAlignment)
              }
              aria-label="Platform"
              size="small" // size 속성 추가
            >
              <ToggleButton value="attend" style={{ width: "70px" }}>
                {row.state === 2 ? "출석!" : "출석?"}
              </ToggleButton>
              <ToggleButton value="noshow" style={{ width: "70px" }}>
                {row.state === 0 ? "노쇼!" : "노쇼?"}
              </ToggleButton>
            </ToggleButtonGroup>
          ) : row.crew_id === hashids.decode(crewId)[0] &&
            isMemberCheckInTime(activity_time) &&
            row.state > 0 ? (
            <div>
              {animate && (
                <div className="attendance-animate-overlay">
                  <div className="heart"></div>
                  <div className="attendance-animate-text">출석 완료</div>
                </div>
              )}
              <ToggleButtonGroup
                color="primary"
                value={
                  row.state === 2 ? "attend" : row.state === 0 ? "noshow" : ""
                }
                exclusive
                onChange={(event, newAlignment) =>
                  handlepwClickOpen(row.crew_id, event, row.state, newAlignment)
                }
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="attend" style={{ width: "70px" }}>
                  {row.state === 2 ? "출석!" : "출석?"}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          ) : row.state === 2 ? (
            "출석"
          ) : row.state === 0 ? (
            "노쇼"
          ) : (
            "신청"
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Toolbar key="tb">
        <Typography key="tg1" variant="h7">
          {text}
        </Typography>
        <Typography
          key="tg2"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        />
        &ensp; <SearchIcon />
        <InputBase
          key="ib"
          type="text"
          className="search"
          onChange={handleChange}
          placeholder="Search..."
        />
      </Toolbar>
      <Table key="t">
        <TableHead key="th">
          <TableRow key="tr">
            {lst_col.map((c) => {
              return (
                <TableCell
                  key={c}
                  style={{ color: "#3f50b5" }}
                  onClick={() => handleSort(c)}
                >
                  {c}
                  {c === "" ? "" : <SortIcon sx={{ fontSize: 15 }} />}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {lst_col[2] === "가입일" ? tableData : tableAttendeeData}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>소그룹 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택한 소그룹으로 변경하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={handleConfirmChange} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={pwopen} onClose={handlepwClose}>
        <DialogTitle>Attendance Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            운영진에게 출석 코드를 문의하세요.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            fullWidth
            value={password}
            onChange={handlepwChange}
          />
          {pwError && (
            <>
              <Typography color="error">{pwError}</Typography>
              <Typography variant="body2">
                남은 시도 횟수: {totalAttempts - attempts}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlepwClose} color="primary">
            취소
          </Button>
          <Button onClick={handlepwConfirm} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CrewList;
